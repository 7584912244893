import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useTranslation } from 'react-i18next';

const TreatmentDocPage = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { homeContent } = useSelector(state => state.AuthReducer);

  const { currentLanguage } = useSelector(state => state.commonReducer);

  let [doctors, setDoctors] = useState([]);

  useEffect(() => {
    console.log(doctors, 'doctors')
    if (homeContent?.length > 0) {
      //eslint-disable-next-line
      setDoctors(homeContent?.filter(val => {
        if (val?.type === 'doctor' && (val?.img_url && val?.title)) {
          return val;
        }
      })?.map(val => {
        let finalLanguage = val?.language?.find(lang => lang?.language_code === currentLanguage);
        return { ...val, finalLanguage: finalLanguage };
      }))
    }
  }, [homeContent, currentLanguage]);

  const handleViewMore = () => {
    navigate('/ourdoctors');
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <MdOutlineArrowBackIosNew />,
    nextArrow: <MdOutlineArrowForwardIos />,
  };

  return (
    <div className='container-fluid' style={{ backgroundColor: '#ededed', marginBottom: '3rem', padding: '3rem 0' }}>
      <div className='container'>
        <div className='treatment-page-container'>
          <div className="page_heading_div_cls mb-5">
            {t("Our Doctor")}
          </div>
          <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
            {doctors?.map((val, i) => (
              <div key={i} className='consult-doctors-list-main-div'>
                <img className='home-doctor-img' src={val?.img_url} alt="Farmers" />
                <div style={{ lineHeight: 1.2 }}>
                  <p style={{ color: 'rgb(6 6 6)', fontWeight: '500', fontSize: '22px', marginTop: '1rem', fontFamily: 'NotoSans-Medium' }}>{val?.finalLanguage?.title || val?.title}</p>
                  <p style={{ color: '#dc7f92', fontSize: '18px', margin: '1rem 0' }}>{val?.finalLanguage?.contents[0]?.experience || val?.language[0]?.contents[0]?.experience}</p>
                  <p style={{ color: 'rgb(35 44 99)', fontWeight: '500', fontFamily: 'NotoSans-Medium', fontSize: '18px',minHeight:"45px", maxHeight:"45px",maxWidth: '100%' }}>{val?.finalLanguage?.contents[0]?.designation || val?.language[0]?.contents[0]?.designation}</p>

                </div>
              </div>
            ))}
            {/* <div onClick={handleViewMore}>
              <div className="container treatment-doctor-view-all-div" >
                <h5 className="farm-equipment-view-all-btn" >{t("View All")} <IoIosArrowForward /></h5>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TreatmentDocPage;
