import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import TreatmentDocPage from './doctortreatment';
import TreatmentCard from './consultationtopcard';
import CommonDiseasesPage from './CommonDiseases'
import { PiChatText } from "react-icons/pi";
import homeActions from '../../Redux/Home/action';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LogEvent } from '../../utils/firebase';
import { DecryptFunction } from '../../utils/cryptoFunction';
import { useNavigate, useSearchParams } from "react-router-dom";

const FaqAccordionConsulation = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [faqItems, setFaqItems] = useState([
    {
      question: {
        en: 'Why is my cow giving low milk?',
        tm: 'என்னுடைய மாடு ஏன் குறைவாக பால் கறக்கிறது?',
        mr: 'माझी गाय कमी दूध का देते?'
      },
      answer: {
        en: 'Right feeding is important to increase milk production. Feeding should based on body weight, milk production, and FAT/SNF content. Know your cow feed requirement from experts of YourFarm.',
        tm: 'பால் உற்பத்தியை அதிகரிக்க சரிவிகித உணவு முக்கியம். உடல் எடை, பால் உற்பத்தி மற்றும் FAT/SNF ஆகியவற்றின் அடிப்படையில் தீவனம் அளிக்கப்பட வேண்டும். உங்களுடைய  மாட்டுக்குத் தேவையான தீவன அளவு பற்றி தெரிய வேண்டுமா?',
        mr: 'दूध उत्पादन वाढवण्यासाठी योग्य आहार देणे महत्त्वाचे आहे. शरीराचे वजन, दुधाचे उत्पादन आणि FAT/SNF सामग्रीवर आधारित श्पल्ड फीडिंग. तुमच्या गाईच्या चारा ची गरज माहीत आहे का? आपल्या गाईची खाद्य गरज जाणून घ्या. पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'How to feed green fodder for dairy cows?',
        tm: 'மாட்டுக்கு பசுந்தீவனம் கொடுப்பது எப்படி?',
        mr: 'गाईला हिरवा चारा कसा द्यावा?'
      },
      answer: {
        en: 'Dairy cows need 65% of grass and 35% leguminous green fodder. For example, in case a dairy cow requires 30 kg of green fodder split this into 20 to 22 kg of grasses and 8 to 10 kg of leguminous green fodder. Call your YourFarm veterinarian for further guidance.',
        tm: 'மாட்டுக்கு பசுந்தீவனம் கொடுக்கும் கலவையில் பசும்புல் 65%மும், பயறுவகை பசுந்தீவனம் 35%மும் இருக்க வேண்டும்.30 கிலோ பசுந்தீவனம் எனில், 20 முதல் 22 கிலோ வரை  பசும்புல் வகைகளையும், 8 முதல் 10 கிலோ வரை பயறுவகை பசுந்தீவனங்களையும் கலந்து கொடுக்க வேண்டும்.மேலும் வழிகாட்டுதலுக்கு கால்நடை மருத்துவரை அழைக்கவும்',
        mr: 'गाईच्या चाऱ्याच्या मिश्रणात 65% गवत आणि 35% शेंगांचा हिरवा चारा असावा.30 किलो हिरवा चारा, 20 ते 22 किलो गवत आणि 8 ते 10 किलो शेंगा हिरवा चारा मिसळावा. अधिक जाणून घ्या पशुवैद्यकीय डॉक्टरांकडून'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'How to increase the FAT/SNF content in milk?',
        tm: 'பாலில் FAT/SNF அளவை  அதிகரிப்பது எப்படி?',
        mr: 'दुधात एसएनएफ/फॅटची पातळी कशी वाढवायची?'
      },
      answer: {
        en: "Increase fat content in cow's milk by providing a balanced diet with adequate green fodder and dry fodder, concentrate feed, and mineral mixture as per the cow's body weight, milk production capacity, and fat/SNF in milk. Ensure proper milking practices and overall herd management for optimal results. Reach out to the vet within a minute for further information. ",
        tm: 'போதுமான பசுந்தீவனம் மற்றும் உலர் தீவனம், பசுவின் உடல் எடை, பால் உற்பத்தி திறன் அளவிற்கு ஏற்ப அடர் தீவனம் மற்றும் தாது உப்புக் கலவையுடன் சமச்சீர் உணவை வழங்குவதன் மூலம் பசுவின் பாலில் கொழுப்பு மாறும் SNF அளவை    அதிகரிக்கலாம். மேலும், முறையான பால் கறக்கும் நடைமுறைகள் மற்றும் ஒட்டுமொத்த பண்ணை மேலாண்மை ஆகியவற்றை சரியாக நடக்கிறதா என உறுதி செய்யவும். ஒரு நிமிடத்தில் கால்நடை மருத்துவரை அணுக...',
        mr: 'पुरेशी ऊर्जा आणि प्रथिनांसह संतुलित आहार देऊन, चरबीच्या पूरक आहारांचा समावेश करून आणि उच्च-चरबी-उत्पादन देणाऱ्या दुग्धजन्य जाती निवडून गाईच्या दुधात चरबीचे प्रमाण वाढवा. इष्टतम परिणामांसाठी योग्य दुग्धशैली आणि एकूण कळप व्यवस्थापन सुनिश्चित करा.""पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },
    {
      question: {
        en: 'When should I start feeding green fodder or grass to my calves?',
        tm: 'நான் எப்போது என் கன்றுகளுக்கு புல் கொடுக்க ஆரம்பிக்க வேண்டும்?',
        mr: 'मी माझ्या वासरांना गवत कधी खायला सुरुवात करावी?'
      },
      answer: {
        en: "You can introduce grass to your calves' diet once they are about 2-3 weeks old. Want to know about calf feeding? Get in touch with our vet team.",
        tm: 'இளம்கன்றுகளுக்கு  2-3 வாரங்களில்  இளம் பசும்புல்லை கொடுக்கலாம்.  கன்றுக்குட்டிக்கு தீவனம் கொடுக்கும் முறை  பற்றி தெரிய வேண்டுமா?',
        mr: 'तुमच्या वासरे 2-3 महिन्यांची झाल्यावर तुम्ही त्यांच्या आहारात गवत समाविष्ट करणे सुरू करू शकता. गायींच्या खाद्य बद्दल जाणून घ्या.'
      },
      isOpen: false,

    },
    {
      question: {
        en: 'How much mineral mix (salt) must be given to cow?',
        tm: 'மாட்டுக்கு எவ்வளவு தாது உப்பு கலவை கொடுக்க வேண்டும்?',
        mr: 'गायीला किती खनिज मिश्रण (मीठ) द्यावे?'
      },
      answer: {
        en: "The amount of mineral mix (salt) to be given to a cow depends on various factors such as the cow's weight, age, breed, and overall health. Call a veterinarian for more information",
        tm: 'ஒரு மாட்டுக்கு கொடுக்கப்படும் தாதுக் கலவை (உப்பு) அளவு, பசுவின் எடை, வயது, இனம் மற்றும் ஒட்டுமொத்த ஆரோக்கியம் போன்ற பல்வேறு காரணிகளைப் பொறுத்தது. மேலும் தகவல்களை பெற கால்நடை வல்லுநர்களை அழையுங்கள்',
        mr: 'गाईला किती खनिज मिश्रण (मीठ) द्यायचे हे गाईचे वजन, वय, जात आणि एकूण आरोग्य यासारख्या विविध घटकांवर अवलंबून असते.""पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },

    {
      question: {
        en: 'How to reduce the amount spent on cattle feed?',
        tm: 'கால்நடை தீவனத்திற்காக செலவழிக்கும் தொகையை குறைப்பது எப்படி?',
        mr: 'गुरांच्या चाऱ्यावर खर्च होणारी रक्कम कशी कमी करायची?'
      },
      answer: {
        en: "Feed costs in the dairy cow can be reduced by following right feeding practices as per the nutritional requirements, avoiding wastage, and sourcing cost-effective feeds. Get advice on fodder management",
        tm: 'மாடுகளின் வளர்ச்சி மற்றும் ஊட்டச்சத்து தேவைகளுக்கு ஏற்ப சரியான தீவனப் பராமரிப்பு முறைகளைப் பின்பற்றினாலே, அளவுக்கு அதிகமாக கொடுக்கப்படும் தீவனத்தை குறைப்பதன் மூலம் தீவன செலவைக் குறைக்கலாம்.தீவனப் பராமரிப்பு முறை பற்றி ஆலோசனை பெறுங்கள்',
        mr: 'खाद्य पद्धती अनुकूल करून, अपव्यय टाळून आणि किफायतशीर पर्याय मिळवून पशुखाद्याचा खर्च कमी करा.""पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'How much green fodder can I give to my cow?',
        tm: 'எனது மாட்டுக்கு எவ்வளவு கிலோ பசுந்தீவனம் கொடுக்கலாம்?',
        mr: 'मी माझ्या गायीला किती हिरवा चारा देऊ शकतो? दररोज 30 किलो हिरवा चारा देता येतो ?'
      },
      answer: {
        en: "30kg of green fodder can be given to dairy cows per day. Split the quantity into 10 kg and feed in equal intervals i.e. Morning-10kg, Afternoon- 10kg, and Evening- 10kg. Talk to YourFarm Doctor to learn more about green fodder feeding.",
        tm: ' ஒரு நாளைக்கு 30 கிலோ பசுந்தீவனம் கொடுக்க வேண்டும். காலை-10கிலோ, மதியம்-10கிலோ, மாலை-10கிலோ என பிரித்து வழங்க வேண்டும்.பசுந்தீவனம் கொடுப்பது பற்றி முழுமையாக தெரிந்து கொள்ள யுவர்பார்ம் டாக்டரிடம் பேசுங்கள்',
        mr: 'रक्कम विभाजित करा उदा. सकाळी - 10 किलो, दुपारी - 10 किलो आणि संध्याकाळी - 10 किलो प्रदान करणे आवश्यक आहे.पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'When does a cow give peak milk production?',
        tm: 'கறவை மாட்டுக்கு எப்போது உச்சபட்ச பால் சுரக்கும்?',
        mr: ' गाय सर्वाधिक दूध उत्पादन कधी देते ?'
      },
      answer: {
        en: " The cow reaches its peak milk production between one and a half to two months after calving. Peak yield will remain for up to 3 months. Want to increase milk production? Consult a veterinarian",
        tm: 'கன்று ஈன்ற ஒன்றரை - 2 மாதங்களில் மாடு அதன் உச்சபட்ச பால் உற்பத்தியை அடையும். 2 மாதம் முதல்  3 மாதங்கள் வரை இருக்கும்.பால் உற்பத்தியை அதிகரிக்கணுமா? கால்நடை வல்லுநர்களை அணுகுங்கள்',
        mr: 'दीड ते २ महिन्यात गाय दूध उत्पादनाचा उच्चांक गाठते. हे 3 महिन्यांपर्यंत राहील. दूध उत्पादन वाढवा. पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },
    {
      question: {
        en: 'Is it necessary to give a mineral mixture to my milking cow?',
        tm: 'என்னுடைய கறவை மாட்டுக்கு தாது கலவை கொடுக்க வேண்டியது அவசியமா?',
        mr: 'आपण गायींना खनिज मिठाचे मिश्रण द्यावे का? '
      },
      answer: {
        en: "Yes. Mineral deficiencies in cows lead to low milk production, fat, and SNF content in milk. Feeding Mineral mixture regularly helps to improve the milk production, fat & snf content in milk and by increasing the price of the milk. Consult a veterinarian for immediate advice.",
        tm: ' ஆம். மாட்டுக்கு தேவையான தாது சத்துக்கள் குறைந்தால் பால் உற்பத்தி குறையும்.எனவே, தாது உப்புக்  கலவையை தொடர்ந்து கொடுப்பதன் மூலம் பால் உற்பத்தி மற்றும் பாலில் உள்ள கொழுப்பு அதிகரிக்க உதவுகிறது. இதன் மூலம் பாலுக்கு கூடுதல் விலை கிடைக்க உதவுகிறது. உடனடி ஆலோசனைக்கு  கால்நடை மருத்துவரை அணுகவும்',
        mr: 'गाईंना खनिज मिठाचे मिश्रण दिल्याने दुधात SNF पातळी वाढून दुधाचे उत्पादन वाढण्यास मदत होते.पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },
    {
      question: {
        en: 'How much investment is needed to start a dairy farm?',
        tm: 'பால் பண்ணை தொடங்க எவ்வளவு பணம் தேவைப்படும்?',
        mr: 'डेअरी फार्म सुरू करण्यासाठी किती पैसे लागतात?'
      },
      answer: {
        en: "The amount of money needed to start a dairy farm can vary widely depending on various factors such as the size of the operation, location, equipment needed, land costs, etc., Talk to YourFarm's veterinary experts to get assistance on setting up a new farm. ",
        tm: 'ஒரு பால் பண்ணை தொடங்குவதற்குத் தேவைப்படும் பணத்தின் அளவானது  மாடுகளின் இனம் மற்றும்  எண்ணிக்கை, இடம், தேவையான உபகரணங்கள், மற்றும் தீவனச் செலவுகள்  போன்ற பல்வேறு காரணிகளைப் பொறுத்து பரவலாக மாறுபடும்.புதிதாக பண்ணையம் அமைக்க  யுவர்பார்ம் கால்நடை வல்லுனர்களிடம் பேசி ஆலோசனை பெறுங்கள்',
        mr: 'डेअरी फार्म सुरू करण्यासाठी लागणारी रक्कम विविध घटकांवर अवलंबून असते जसे की ऑपरेशनचा आकार, स्थान, आवश्यक उपकरणे, जमिनीची किंमत""पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },
    {
      question: {
        en: 'How soon should the calf be given colostrum after birth?',
        tm: 'பிறந்த கன்றுக்கு எவ்வளவு நேரத்திற்குள் சீயம்பால் கொடுக்க வேண்டும்?',
        mr: 'आपण वासराला किती कोलोस्ट्रम द्यावे?'
      },
      answer: {
        en: " Colostrum from the mother cow should be given immediately after birth i.e. within 15 minutes to 30 minutes. Consult a veterinarian to know more about healthy calf rearing.",
        tm: 'கன்று பிறந்த 15 நிமிடம் முதல் 30 நிமிடங்களுக்குள் கொடுக்க வேண்டும்.கன்று வளர்ப்பு  பற்றி கால்நடை மருத்துவரை அணுகுங்கள்',
        mr: 'गाईचे कोलोस्ट्रम जन्मानंतर लगेच द्यावे, म्हणजे 15 ते 30 मिनिटांच्या आत. पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,

    },
  ]);

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
    //eslint-disable-next-line
  }, []);

  const toggleAccordion = (index) => {
    setFaqItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  return (
    <>
      <div className="treatment-page-container mt-3">
        
        <TreatmentCard />
        {/* <div className="custom-card-consultationandtreatment container">
          <div className="customtreatment-heading" style={{ padding: "0 10px;" }}>
            <strong>{t("How it Works")}?</strong>
          </div>
          <div className="d-flex justify-content-around circular-tab-container">
            <div>
              <div className='message-circle' style={{ background: 'rgba(209, 242, 204, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label" style={{ color: 'black' }}>{t("24/7 support")}</div>
            </div>
            <div className="connector"></div>
            <div >
              <div className="message-circle" style={{ background: 'rgba(255, 209, 194, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label" style={{ color: 'black' }}>{t("Consult with Vet")}</div>
            </div>
            <div className="connector"></div>
            <div>
              <div className="message-circle" style={{ background: 'rgba(254, 233, 161, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label" style={{ color: 'black' }}>{t("Buy right feed for your animal")}</div>
            </div>
          </div>
        </div> */}
        <CommonDiseasesPage />
        <div className="custom-card-treatment mt-4 container">
          <div className="custom-content">
            <h2 className="custom-heading">{t("Check our range of services")}</h2>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <p className="custom-paragraph">
            {t('The health of your animals is our top priority. Our veterinary doctors are available 24/7 to support you. We’re dedicated to helping farmers build successful and sustainable dairy operations. Reach out anytime—we’re here to help!')} </p>
            </div>
            <div className="custom-buttons">
              <button className="custom-button call-button" onClick={() => {
                const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                LogEvent('Floating_Call_Button_Clicked');
                window.location.href = `tel:${phoneNumber}`;
              }}>{t("Call Now")}</button>

              <button
                className="custom-button explore-button"
                onClick={() => navigate('/')}
              >
                {t("Explore")}
              </button>            </div>
          </div>
        </div>
        <div className="consult-frequent-div mt-5" style={{ backgroundColor: "#F6F7FF" }}>
          <Container>
            <div className='frequent-heading-cls'>{t("Frequently Asked Questions")}</div>
            {faqItems.map((item, index) => (
              <div key={index} className='fre-ques-map-div'>
                <div
                  className={`faq-question ${item.isOpen ? 'open' : ''} individual-question`}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className='frequent-questions-span'>{item.question[i18n.language]}</span>
                  {item.isOpen ? (
                    <IoIosArrowUp />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </div>
                {item.isOpen && (
                  <div>
                    <div className="faq-answer">{item.answer[i18n.language]}</div>
                  </div>
                )}
              </div>
            ))}
          </Container>
        </div>
        <TreatmentDocPage />
      </div>
    </>
  );
};

export default FaqAccordionConsulation;
