import { FiShoppingCart } from "react-icons/fi";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { priceRoundOff } from "../../utils/validation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ProductListView = ({ Products, handleViewDetails, handleAddToCart, handleQtyValue }) => {
    const { t } = useTranslation();
    const { cartProducts } = useSelector(state => state.cartReducer);
    const location = useLocation()
    const { products, selectedProductFilter,ProductList } = useSelector(state => state.ProductListReducer);
    const { currentLanguage } = useSelector(
        (state) => state.commonReducer
      );

    useEffect(() => {
        console.log(Products,currentLanguage, 'products');
        
        
    })


    return (
        <>
            {Products?.length > 0 ?
                <>
                    {/* {Products?.map((val, i) =>
                        <div className="product-list-view-card-div" key={i}>
                            <div className="row g-0">
                                <div className="col-md-3 col-sm-4 d-flex align-items-center product_list_view_col1_div">
                                    {val?.discount_status === 1 &&
                                        <>
                                            {(val.discount_amount > 0) && (
                                                <span className="product-discount-span">
                                                    <>
                                                        {val?.discount_type === 1 ?
                                                            `
                                                            ${val.discount_amount}% OFF` :
                                                            `- ₹${val.discount_amount} OFF`
                                                        }
                                                    </>
                                                </span>
                                            )}
                                        </>
                                    }
                                    <img src={val?.image_url} className="card-img-top card-grid-view-img cursor-pointer" alt="..." onClick={(e) => handleViewDetails(e, val?.id, val)} />
                                </div>
                                <div className="col-md-9 col-sm-8 product_list_view_col2_div">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <h6 className="product-grid-view-name cursor-pointer" onClick={(e) => handleViewDetails(e, val?.id, val)}>
                                                {val?.finalLanguage?.medicine_name || val?.medicine_name}
                                            </h6>
                                            <select className="product_list_card_qty_select_cls" onChange={(e) => handleQtyValue(e, val, i)}>
                                                {Array.from({ length: val.max_qty - val.min_qty + 1 }, (_, index) => val.min_qty + index).map((qty, ind) =>
                                                    <option key={ind} selected={qty === (cartProducts?.order_items?.find((item) => item?.product_id === val?.id)?.quantity)}>
                                                        {qty}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <h6 className="product-list-view-desc my-3 cursor-pointer" onClick={(e) => handleViewDetails(e, val?.id, val)}>
                                            {val?.finalLanguage?.discription || val.discription}
                                        </h6>
                                        <div className="mb-2 d-flex justify-content-between align-items-center products-list-view-bottom-price-btn-div">
                                            <div>
                                                {(val?.discount_status === 1) ?
                                                    <>
                                                        <span className="product-list-view-product-amount-del-tag me-1"><MdOutlineCurrencyRupee /><del >{val.amount}</del></span>
                                                        {(val?.discount_type === 1) ?
                                                            <span className="product-final-price ms-1"> <MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - (val?.amount * val?.discount_amount) / 100)}/-</span> :
                                                            <span className="product-final-price ms-1"><MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - val?.discount_amount)}/-</span>
                                                        }
                                                    </> :
                                                    <span className="product-final-price"><MdOutlineCurrencyRupee />{val?.amount}/-</span>
                                                }
                                            </div>
                                            <Button
                                                type="button"
                                                size="sm"
                                                className="product-listview-addtocart"
                                                title="Add to cart"
                                                onClick={(e) => handleAddToCart(e, val)}
                                                
                                            >
                                                <FiShoppingCart size={16} /> <span className="ms-2">{t("Add To Cart")}</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                    {Products?.map((val, i) =>
    <div className="product-list-view-card-div" key={i} onClick={(e) => handleViewDetails(e, val?.id, val)}>
        <div className="row g-0">
            <div className="col-md-3 col-sm-4 d-flex align-items-center product_list_view_col1_div">
                {val?.discount_status === 1 &&
                    <>
                        {(val.discount_amount > 0) && (
                            <span className="product-discount-span">
                                <>
                                    {val?.discount_type === 1 ?
                                        `${val.discount_amount}% OFF` :
                                        `- ₹${val.discount_amount} OFF`
                                    }
                                </>
                            </span>
                        )}
                    </>
                }
                <img src={val?.image_url} className="card-img-top card-grid-view-img cursor-pointer" alt="..." />
            </div>
            <div className="col-md-9 col-sm-8 product_list_view_col2_div">
                <div>
                    <div className="d-flex justify-content-between">
                        <h6 className="product-grid-view-name cursor-pointer">
                            {/* {val?.finalLanguage?.medicine_name || val?.medicine_name} */}
                            {val?.language.find(lang => lang.language_code === currentLanguage)?.medicine_name}
                            {/* {doctor.language.find(lang => lang.language_code === currentLanguage)?.title} */}
                        </h6>
                        <select className="product_list_card_qty_select_cls" onChange={(e) => handleQtyValue(e, val, i)} onClick={(e) => e.stopPropagation()}>
                            {Array.from({ length: val.max_qty - val.min_qty + 1 }, (_, index) => val.min_qty + index).map((qty, ind) =>
                                <option key={ind} selected={qty === (cartProducts?.order_items?.find((item) => item?.product_id === val?.id)?.quantity)}>
                                    {qty}
                                </option>
                            )}
                        </select>
                    </div>
                    <h6 className="product-list-view-desc my-3 cursor-pointer">
                        {/* {val?.finalLanguage?.discription || val.discription} */}
                        {val?.language.find(lang => lang.language_code === currentLanguage)?.discription}
                    </h6>
                    <div className="mb-2 d-flex justify-content-between align-items-center products-list-view-bottom-price-btn-div">
                        <div>
                            {(val?.discount_status === 1) ?
                                <>
                                    <span className="product-list-view-product-amount-del-tag me-1"><del >{val.amount}</del></span>
                                    {/* <span className="product-list-view-product-amount-del-tag me-1"><MdOutlineCurrencyRupee /><del >{val.amount}</del></span> */}
                                    {(val?.discount_type === 1) ?
                                        <span className="product-final-price ms-1"> <MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - (val?.amount * val?.discount_amount) / 100)}/-</span> :
                                        <span className="product-final-price ms-1"><MdOutlineCurrencyRupee />{priceRoundOff(val?.amount - val?.discount_amount)}/-</span>
                                    }
                                </> :
                                <span className="product-final-price">₹{val?.amount}/-</span>
                                // <span className="product-final-price"><MdOutlineCurrencyRupee />{val?.amount}/-</span>
                            }
                        </div>
                        <Button
                            type="button"
                            size="sm"
                            className="product-listview-addtocart"
                            title="Add to cart"
                            onClick={(e) => { e.stopPropagation(); handleAddToCart(e, val); }}
                        >
                            <FiShoppingCart size={16} /> <span className="ms-2">{t("Add To Cart")}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

                </> :
                <>
                    <div className="product-notfound-cls">
                        <h4>{t("No Products Found")}</h4>
                    </div>
                </>
            }
        </>
    );
};

export default ProductListView;
