// const actions = {
//    SEND_OTP:'SEND_OTP',
//    VERIFY_OTP:'VERIFY_OTP',
//    GET_USER_PROFILE:'GET_USER_PROFILE',
//    SET_USER_PROFILE:'SET_USER_PROFILE',
//    GET_USER_UPDATE_PROFILE: ' GET_USER_UPDATE_PROFILE',
//    SET_USER_UPDATE_PROFILE: ' SET_USER_UPDATE_PROFILE',
//    GET_USER_FARM_PLACE:'GET_USER_FARM_PLACE',
//    SET_USER_FARM_PLACE:'SET_USER_FARM_PLACE',
//    GET_PRODUCTS_HOME:"GET_PRODUCTS_HOME",
//    SET_PRODUCTS_HOME:"SET_PRODUCTS_HOME",
//    STORE_ID_ACTION:"STORE_ID_ACTION",
//    GET_HOME_CONTENTS: 'GET_HOME_CONTENTS',
//    SET_HOME_CONTENTS: 'SET_HOME_CONTENTS',
//    GET_HOME_MARKETPLACE_CATTLE_LIST: 'GET_HOME_MARKETPLACE_CATTLE_LIST',
//    SET_HOME_MARKETPLACE_CATTLE_LIST: 'SET_HOME_MARKETPLACE_CATTLE_LIST',
//    HOME_RESPONSE: 'HOME_RESPONSE',
//    GET_PRIVACY_POLICY: 'GET_PRIVACY_POLICY',
//    SET_PRIVACY_POLICY: 'SET_PRIVACY_POLICY'

// }
// export default actions;
const actions = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  SET_USER_PROFILE: "SET_USER_PROFILE",
  GET_USER_UPDATE_PROFILE: " GET_USER_UPDATE_PROFILE",
  SET_USER_UPDATE_PROFILE: " SET_USER_UPDATE_PROFILE",
  GET_USER_FARM_PLACE: "GET_USER_FARM_PLACE",
  SET_USER_FARM_PLACE: "SET_USER_FARM_PLACE",
  GET_PRODUCTS_HOME: "GET_PRODUCTS_HOME",
  SET_PRODUCTS_HOME: "SET_PRODUCTS_HOME",
  STORE_ID_ACTION: "STORE_ID_ACTION",
  GET_HOME_CONTENTS: "GET_HOME_CONTENTS",
  SET_HOME_CONTENTS: "SET_HOME_CONTENTS",
  GET_TEAM_CONTENTS: "GET_TEAM_CONTENTS",
  SET_TEAM_CONTENTS: "SET_TEAM_CONTENTS",
  GET_HOME_MARKETPLACE_CATTLE_LIST: "GET_HOME_MARKETPLACE_CATTLE_LIST",
  SET_HOME_MARKETPLACE_CATTLE_LIST: "SET_HOME_MARKETPLACE_CATTLE_LIST",
  HOME_RESPONSE: "HOME_RESPONSE",
  GET_PRIVACY_POLICY: "GET_PRIVACY_POLICY",
  SET_PRIVACY_POLICY: "SET_PRIVACY_POLICY",
};
export default actions;
