import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import playstore from '../../assets/images/playstore.png';
import mobile from '../../../src/assets/images/new_home_page/Static banner for app install in landing page.png'; // Update the path accordingly
import mobile2 from '../../../src/assets/images/new_home_page/yourfarm_mobile_img.png'
import { t } from 'i18next';
import { LogEvent } from '../../utils/firebase';
import QrCode from "../../assets/images/static YourfarmQR.png"


const AppDownloadSection = () => {
  return (
    // <div className='container-fluid position-relative'>
    //   <img src={mobile} className="mobile-app-image position-absolute" alt="Mobile App" />
    //   <Container className="position-relative">
    //     <div className='home-app-download-section-main'>
    //       <div className="d-flex align-items-center flex-column flex-md-row">
    //         <div className="home_app_text-container mt-3 mt-md-0 me-md-3" style={{ color: '#232C63' }}>
    //           <h3>{t("Download Our App")}</h3>
    //           <p className='mt-3' style={{ fontSize: '15px', color: '#0D1560', lineHeight: '1.5'}}>
    //             {t("Introducing our innovative app where you can seamlessly schedule veterinary appointments for your cattle with just a few taps. Additionally, explore a wide range of cattle-related products and farming essentials available for purchase, all in one convenient platform tailored for your livestock needs.")}
    //           </p>

    //         </div>
    //         <div className='d-flex justify-content-end align-items-center mt-3'>
    //           <ul className="footer-col-content footer-col2-section d-flex flex-column align-items-end ml-auto">
    //             <li
    //               className="text-decoration-none cursor-pointer mb-2 mt-5"
    //               onClick={() => {
    //                 LogEvent('Footer_QRCode_Link');
    //               }}
    //             >
    //               <img src={QrCode} className='app-download-img-qr' alt="QR Code" />
    //             </li>
    //             <li>
    //               <a href="https://play.google.com/store/apps/details?id=com.yourfarm" target="_blank" rel="noopener noreferrer" onClick={() => {
    //                 LogEvent('Home_GooglePlay_Link');
    //               }}>
    //                 <img src={playstore} className='app-download-img-playstore' alt="Google Playstore" />
    //               </a>
    //             </li>
    //           </ul>
    //         </div>




    //       </div>
    //     </div>
    //   </Container>
    // </div>
    <>
    <Container fluid style={{backgroundColor: '#D4D5DD'}}>
      <Container style={{paddingTop: '30px'}}>
        <Row>
          <Col style={{display: 'flex', alignItems: 'end'}} className='mobile_app_image' lg={4} md={6} sm={12}>
          <img  src={mobile2} alt="Mobile App" />
          </Col>

          <Col lg={4} md={6} sm={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '11px'}}>
          <h3 style={{fontSize: '32px', color: '#232C63'}}>{t("Download Our App")}</h3>
               <p className='mt-3' style={{ fontSize: '18px', color: '#0D1560'}}>
                 {t("Introducing our innovative app where you can seamlessly schedule veterinary appointments for your cattle with just a few taps. Additionally, explore a wide range of cattle-related products and farming essentials available for purchase, all in one convenient platform tailored for your livestock needs.")}
               </p>
          </Col>

          <Col lg={4} md={6} sm={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}>
          
          <div style={{marginBottom: '20px'}} onClick={() => {
                     LogEvent('Footer_QRCode_Link');
                   }}>
          <img src={QrCode} className='app-download-img-qr' style={{width: '141px'}} alt="QR Code" />
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.yourfarm" target="_blank" rel="noopener noreferrer" onClick={() => {
                     LogEvent('Home_GooglePlay_Link');
                   }}>
                    <img src={playstore} style={{width: '155px'}} className='app-download-img-playstore' alt="Google Playstore" />
                    </a>
          </Col>
        </Row>
      </Container>

    </Container>
    </>
  );
};

export default AppDownloadSection;
