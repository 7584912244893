import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaPlayCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';  // Import the icons
import { useDispatch, useSelector } from 'react-redux';
import homeActions from '../../Redux/Home/action';
import { useTranslation } from 'react-i18next';
import { LogEvent } from '../../utils/firebase';
import noimg from '../../assets/images/noImage.jpg';

const VideoList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { homeContent } = useSelector(state => state.AuthReducer);
  const { currentLanguage } = useSelector(state => state.commonReducer);
  const [videoList, setVideoList] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
    
    
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (homeContent?.length > 0) {
      const categorizedVideos = {
        "கறவை மாடு": [],
        "நாட்டு மாடு": [],
        "நாட்டு கோழி": [],
        "வெள்ளாடு": [],
        "மற்றவை": []
      };
      homeContent.forEach(val => {
        const finalLanguage = val?.language?.find(lang => lang?.language_code === currentLanguage);
        switch (val?.type) {
          case 'கறவை மாடு':
          case 'நாட்டு மாடு':
          case 'நாட்டு கோழி':
          case 'வெள்ளாடு':
            categorizedVideos[val.type].push({ ...val, image: getImageUrl(val.type), finalLanguage, typeOfContent: 'video' });
            break;
          default:
            categorizedVideos["மற்றவை"].push(val);
            break;
        }
      });

      setVideoList(categorizedVideos);
      console.log(videoList, 'video list');
    }
  }, [homeContent, currentLanguage]);

  const getImageUrl = (type) => {
    switch (type) {
      case 'கறவை மாடு':
        return 'https://yourfarm-mobile-app.s3.ap-south-1.amazonaws.com/1677569429374';
      case 'நாட்டு மாடு':
        return 'https://yourfarm-mobile-app.s3.ap-south-1.amazonaws.com/1677569625077';
      case 'நாட்டு கோழி':
        return 'https://yourfarm-mobile-app.s3.ap-south-1.amazonaws.com/1677570029763';
      case 'வெள்ளாடு':
        return 'https://yourfarm-mobile-app.s3.ap-south-1.amazonaws.com/1677569839899';
      default:
        return '';
    }
  };

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedVideo('');
  };

  const toggleCategoryExpansion = (category) => {
    setExpandedCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
  };

  return (
    <>
      <div className="container my-5">
        <div className="mb-4">
          <div className="page_heading_div_cls">
            {t("Videos")}
          </div>
        </div>

        {Object.keys(videoList).map((category) => {
          const videos = videoList[category];
          const shownVideos = expandedCategories[category] ? videos : videos.slice(0, 3);
          const isExpanded = expandedCategories[category];

          return (
            <div key={category}>
              <div className='d-flex justify-content-between mt-4'>
                <h3 className='video-list'>{t(category)}</h3>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleCategoryExpansion(category);
                  }}
                  className="mb-2 change-address-link"
                >
                  {isExpanded ? (
                    <>
                      {t("Show Less")} <FaChevronUp /> {/* Show Less with Up Arrow */}
                    </>
                  ) : (
                    <>
                      {t("Show More")} <FaChevronDown /> {/* Show More with Down Arrow */}
                    </>
                  )}
                </a>
              </div>
              <div className="row row-padding-remover">
                {Array.isArray(shownVideos) && shownVideos.map((video) => (
                  <div key={video.id} className="col-md-4 col-sm-6 col-12 mb-3">
                    <div
                      variant="link"
                      onClick={() => {
                        LogEvent('Videos_Play_Clicked');
                        handleVideoClick(video.link);
                      }}
                      className="position-relative video-blog-page-btn"
                    >
                      <img
                        src={video?.img_url || `https://img.youtube.com/vi/${video?.youtube_video_id}/0.jpg` || noimg}
                        alt={`Video ${video.id}`}
                        className="img-fluid video-blog-page-img"
                        style={{ cursor: 'pointer' }}
                      />
                      <FaPlayCircle
                        size={50}
                        className="play-button position-absolute"
                      />
                      <div className='video-blog-page-title mb-1'>{video?.title}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("YourFarm")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="YouTube Video"
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${selectedVideo?.split("/")[selectedVideo?.split("/").length - 1]}`}
            frameBorder="0"
            allow="autoplay; encrypted-media;"
            allowFullScreen
          ></iframe>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default VideoList;
