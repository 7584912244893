import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LogEvent } from "../../utils/firebase";
import QrCode from "../../assets/images/static YourfarmQR.png";
import Instagram from "../../assets/images/socailmedia/instagram.png";
import Facebook from "../../assets/images/socailmedia/facebook.png";
import Youtube from "../../assets/images/socailmedia/youtube.png";
import LinkedIn from "../../assets/images/socailmedia/linkedin.png";
import HomeActions from "../../Redux/Home/action";
import footer_bottom from '../../assets/images/new_home_page/footer_bottom.png'

const Footer = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { websiteLanguages, currentLanguage } = useSelector(
    (state) => state.commonReducer
  );
  const { PrivacyPolicy } = useSelector((state) => state.AuthReducer);

  // console.log("check-001",PrivacyPolicy.data.map(privacyPolicy,i)=>(
  //   {privacyPolicy?.language?.find((lang)=>lang?.language_code ==== currentLanguage)?.content}
  // ))

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      dispatch({ type: HomeActions.GET_PRIVACY_POLICY });
    }
  }, [dispatch]);

  const isTamil = i18n.language === "tm";

  return (
    <>
    <Container fluid style={{backgroundColor: '#232C63', paddingBottom: '40px', paddingTop: '20px'}}>
      <Container>
      <Row>
        <Col lg={3} md={6} sm={12} xs={12}>
        <h3 className="footer-col-header">
                    {t("Animeta Agritech Private Limited")}
                  </h3>
                  <div className="footer-col-content">
                    <p style={{ marginTop: "20px", color: 'white' }}>
                      {t("11/4,Pooja Garden, Kalapatti Main Rd, near CTK restaurant, Civil Aerodrome Post, Coimbatore, Tamil Nadu 641014")}<br />
                      
                    </p>
                  </div>
        </Col>

        <Col lg={3} md={6} sm={12} xs={12}>
        <h3 className="footer-col-header">{t("Popular Products")}</h3>
              <ul className="footer-col-content footer-col2-section">
                <li
                  onClick={() => {
                    LogEvent("Footer_HerbalProducts");
                    navigate("/products?category=herbal");
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Herbal Products")}
                </li>
                <li
                  onClick={() => {
                    LogEvent("Footer_Feeds_Supplements");
                    navigate("/products?category=feedsandsupplement");
                    
                    window.location.reload();
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Feeds Supplements")}
                </li>
                <li
                  onClick={() => {
                    LogEvent("Footer_Marketplace");
                    navigate("/service-providers");
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Market Place")}
                </li>
                <li
                  onClick={() => {
                    LogEvent("Blogs");
                    navigate("/blog");
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Blogs")}
                </li>
                <li
                  onClick={() => {
                    LogEvent("Videos");
                    navigate("/videos");
                  }}
                  className="text-decoration-none text-white cursor-pointer"
                >
                  {t("Videos")}
                </li>
              </ul>
        </Col>

        <Col lg={4} md={6} sm={12} xs={12}>
        <h3 className="footer-col-header">{t("Contact us")}</h3>
              <div className="footer-col-content" style={{color: 'white'}}>
                <p>
                  {t("Customer Care")}:
                  <span
                    style={{
                      display: isTamil ? "block" : "inline",
                      marginBottom: isTamil ? "-24px" : "0px",
                      color: 'white'
                    }}
                  >
                    {currentLanguage === 'mr' ? <span
                    style={{
                      display: isTamil ? "block" : "inline",
                      marginBottom: isTamil ? "-24px" : "0px",
                      color: 'white'
                    }}
                  >+91 80693 75150</span> : <span
                  style={{
                    display: isTamil ? "block" : "inline",
                    marginBottom: isTamil ? "-24px" : "0px",
                    color: 'white'
                  }}
                >+91 63837 17150</span>}
                    
                  </span>
                  <br />
                  {t("Email")}: info@yourfarm.co.in
                  <hr className="footer-contactus-hr" />
                  <section className="mb-3 footer-social-icon-section">
                    <a
                      href="https://www.facebook.com/digitalveterinary/"
                      role="button"
                      title="Follow Us On Instagram"
                      target="_blank"
                      onClick={() => LogEvent("Footer_Instagram_Clicked")}
                      class="image-link"
                    >
                      <img src={Facebook} alt="Instagram QR Code" />
                    </a>

                    <a
                      href="https://www.youtube.com/@yourfarm4270/videos"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent("Footer_Instagram_Clicked")}
                      target="_blank"
                      class="image-link"
                    >
                      <img src={Youtube} alt="Instagram QR Code" />
                    </a>

                    <a
                      href="https://www.instagram.com/yourfarm_veterinary_services"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent("Footer_Instagram_Clicked")}
                      target="_blank"
                      class="image-link"
                    >
                      <img src={Instagram} alt="Instagram QR Code" />
                    </a>

                    <a
                      href="https://www.linkedin.com/company/yourfarm-agritech/"
                      role="button"
                      title="Follow Us On Instagram"
                      onClick={() => LogEvent("Footer_Instagram_Clicked")}
                      target="_blank"
                      class="image-link"
                    >
                      <img src={LinkedIn} alt="Instagram QR Code" />
                    </a>
                  </section>
                </p>
                </div>
        </Col>

        <Col lg={2} md={6} sm={12} xs={12}>
        <h3 className="footer-col-header">
                {t("Download Our Apps")}
              </h3>
              <ul className="footer-col-content footer-col2-section">
                <li
                  className="text-decoration-none text-white cursor-pointer"
                  onClick={() => {
                    LogEvent("Footer_GooglePlay_Link");
                  }}
                >
                  <img
                    src={QrCode}
                    className="app-download-img-google"
                    alt="Google Playstore"
                  />
                </li>
              </ul>
        </Col>
      </Row>
      </Container>

    </Container>

    {/* <Container fluid style={{paddingLeft: '0px', paddingRight: '0px'}}>
      <img style={{width: '100%'}} src={footer_bottom} alt="" />
    </Container> */}

      <Container fluid className="footer-btm-img-container">
        <Row>
          {/* <div className="footer-btm-img-container"> */}
          <div className="footer-content ">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                navigate("/privacy.html");
              }}
              className="footer-privacy-link"
            >
              {t("Privacy Policy")}
            </a>
            <p className="footer-copyright-text">
              &copy; {currentYear} {t("Animeta Agritech. All rights reserved.")}
            </p>
          </div>
          {/* </div> */}
        </Row>
      </Container>
    </>
  );
};

export default Footer;
