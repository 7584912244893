// import React, { useEffect } from "react";
// import { Button, Col, Container, Modal, Row } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import commonActions from "../../Redux/common/actions";
// import { useForm } from "react-hook-form";
// import userActions from "../../Redux/user/actions";
// import { useTranslation } from 'react-i18next';

// export default function UserAddressModal() {

//     const { t } = useTranslation();

//     const dispatch = useDispatch();

//     const { showUserAddressModal, stateList } = useSelector(state => state.commonReducer);

//     const { register, handleSubmit, formState: { errors }, reset } = useForm();

//     useEffect(() => {
//         const data = {
//             address_line1: '',
//             address_line2: '',
//             address_line3: '',
//             city: '',
//             pincode: '',
//             state: '',
//             other_address_details: ''
//         };
//         reset(data)
//     }, [showUserAddressModal]);

//     useEffect(() => {
//         dispatch({ type: commonActions.GET_STATES });
//     }, []);

//     const handleCloseModal = () => {
//         dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: false });
//     };

//     const onSubmit = (data) => {
//         dispatch({ type: userActions.CREATE_USER_ADDRESS, payload: { data: data } });
//     };

//     return (
//         <div>
//             <Modal
//                 dialogClassName="login_modal"
//                 show={showUserAddressModal}
//                 onHide={handleCloseModal}
//                 centered
//             >
//                 <div className="modal_close_icons"
//                     onClick={handleCloseModal}
//                 >
//                     <svg
//                         stroke="currentColor"
//                         fill="currentColor"
//                         stroke-width="0"
//                         viewBox="0 0 24 24"
//                         height="1em"
//                         width="1em"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <path fill="none" d="M0 0h24v24H0z"></path>
//                         <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
//                     </svg>
//                 </div>
//                 <Container className="my-4">
//                     <Row>
//                         <Col md={12}  xs={12} sm={12}   className="login-modal-row">
//                             <div className="login_title">
//                                 <h4 className="login-modal-h5">{t("Add Your Address")}</h4>
//                             </div>
//                         </Col>
//                         <Col md={6}xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Address type")}<span className="fw-bold text-danger">*</span></label>
//                                 <select
//                                     className="form-control"
//                                     {...register('address_type', {
//                                         required: "*required"
//                                     })}
//                                 >
//                                     <option value="Home">Home</option>
//                                     <option value="Work">Work</option>
//                                     <option value="Other">Other</option>
//                                 </select>
//                                 {errors?.address_type &&
//                                     <small className="text-danger">{errors?.address_type?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Door No/ Flat No")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     className="form-control"
//                                     {...register('address_line1', {
//                                         required: "*required"
//                                     })}
//                                 />
//                                 {errors?.address_line1 &&
//                                     <small className="text-danger">{errors?.address_line1?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Street Name")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     className="form-control"
//                                     {...register('address_line2', {
//                                         required: "*required"
//                                     })}
//                                 />
//                                 {errors?.address_line2 &&
//                                     <small className="text-danger">{errors?.address_line2?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Town")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     {...register('address_line3', {
//                                         required: "*required",
//                                         pattern: {
//                                             value: /^[a-zA-Z ]*$/,
//                                             message: "Only alphabet characters are allowed",
//                                         },
//                                     })}
//                                 />
//                                 {errors?.address_line3 &&
//                                     <small className="text-danger">{errors?.address_line3?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("District")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     type='text'
//                                     className="form-control"
//                                     {...register('city', {
//                                         required: "*required",
//                                         pattern: {
//                                             value: /^[a-zA-Z ]*$/,
//                                             message: "Only alphabet characters are allowed",
//                                         },
//                                     })}
//                                 />
//                                 {errors?.city &&
//                                     <small className="text-danger">{errors?.city?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Pincode")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     type="text"
//                                     inputMode="numeric"
//                                     pattern="\d{6}"
//                                     className="form-control"
//                                     {...register('pincode', {
//                                         required: "*required",
//                                         pattern: {
//                                             value: /^\d{6}$/,
//                                             message: "Please enter corrected pin code"
//                                         }
//                                     })}
//                                 />
//                                 {errors?.pincode &&
//                                     <small className="text-danger">{errors?.pincode?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("State")}<span className="fw-bold text-danger">*</span></label>
//                                 <select
//                                     className="form-control"
//                                     {...register('state', {
//                                         required: "*required"
//                                     })}
//                                 >
//                                     {stateList?.map((val, i) =>
//                                         <option key={i}>{val?.state_name}</option>
//                                     )}
//                                 </select>
//                                 {errors?.state &&
//                                     <small className="text-danger">{errors?.state?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Country")}<span className="fw-bold text-danger">*</span></label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     {...register('country', {
//                                         required: "*required",
//                                         pattern: {
//                                             value: /^[a-zA-Z ]*$/,
//                                             message: "Only alphabet characters are allowed",
//                                         },
//                                     })}
//                                     defaultValue='India'
//                                 />
//                                 {errors?.country &&
//                                     <small className="text-danger">{errors?.country?.message}</small>
//                                 }
//                             </div>
//                         </Col>
//                         <Col md={6} xs={12} sm={12} className="pb-3">
//                             <div className="form-group">
//                                 <label className="form-label">{t("Landmark")}</label>
//                                 <input
//                                     className="form-control"
//                                     {...register('other_address_details')}
//                                 />
//                             </div>
//                         </Col>
//                         <Col md={12} xs={12} sm={12} className="pb-3">
//                             <Button size="md" onClick={handleSubmit(onSubmit)}>
//                                 {t("Add Address")}
//                             </Button>
//                         </Col>
//                     </Row>
//                 </Container>
//             </Modal>
//         </div>

//     )
// };

import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../Redux/common/actions";
import { useForm } from "react-hook-form";
import userActions from "../../Redux/user/actions";
import { useTranslation } from 'react-i18next';
import { AlphabetsValidation } from "../../utils/validation";

export default function UserAddressModal() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { showUserAddressModal, stateList } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    useEffect(() => {
        const data = {
            address_line1: '',
            address_line2: '',
            address_line3: '',
            city: '',
            pincode: '',
            state: '',
            other_address_details: ''
        };
        console.log(stateList, "modal");
        
        reset(data);
        //eslint-disable-next-line
    }, [showUserAddressModal]);

    useEffect(() => {
        dispatch({ type: commonActions.GET_STATES });
        //eslint-disable-next-line
    }, []);

    const handleCloseModal = () => {
        dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: false });
    };

    const onSubmit = (data) => {
        dispatch({ type: userActions.CREATE_USER_ADDRESS, payload: { data: data } });
    };

    return (
        <div>
            <Modal
                dialogClassName="login_modal"
                show={showUserAddressModal}
                onHide={handleCloseModal}
                centered
                size="lg"
            >
                <div className="modal_close_icons"
                    onClick={handleCloseModal}
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </div>
                <Container className="my-4">
                    <Row>
                        <Col xs={12} className="login-modal-row">
                            <div className="login_title">
                                <h4 className="login-modal-h5">{t("Add Your Address")}</h4>
                                
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Address type")}<span className="fw-bold text-danger">*</span></label>
                                <select
                                    className="form-select ms-0"
                                    {...register('address_type', {
                                        required: (t("*required"))
                                    })}
                                >
                                    <option value="Home">{t('Home ')}</option>
                                    <option value="Work">{t('Work')}</option>
                                    <option value="Other">{t('Other')}</option>
                                </select>
                                {errors?.address_type &&
                                    <small className="text-danger">{errors?.address_type?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Door No/ Flat No")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    {...register('address_line1', {
                                        required: (t("*required"))
                                    })}
                                />
                                {errors?.address_line1 &&
                                    <small className="text-danger">{errors?.address_line1?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Street Name")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    {...register('address_line2', {
                                        required: (t("*required"))
                                    })}
                                />
                                {errors?.address_line2 &&
                                    <small className="text-danger">{errors?.address_line2?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Village/Taluk/Town")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register('address_line3', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                />
                                {errors?.address_line3 &&
                                    <small className="text-danger">{errors?.address_line3?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("District")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    type='text'
                                    className="form-control"
                                    {...register('city', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                />
                                {errors?.city &&
                                    <small className="text-danger">{errors?.city?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Pincode")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    inputMode="numeric"
                                    pattern="\d{6}"
                                    className="form-control"
                                    {...register('pincode', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^\d{6}$/,
                                            message: "Please enter the valid pincode"
                                        }
                                    })}
                                />
                                {errors?.pincode &&
                                    <small className="text-danger">{errors?.pincode?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("State")}<span className="fw-bold text-danger">*</span></label>
                                <select
                                    className="form-select ms-0"
                                    {...register('state', {
                                        required: (t("*required"))
                                    })}
                                >
                                    {stateList?.map((val, i) =>
                                        <option key={i}>{val?.state_name}</option>
                                    )}
                                </select>
                                {errors?.state &&
                                    <small className="text-danger">{errors?.state?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Country")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register('country', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                    defaultValue='India'
                                />
                                {errors?.country &&
                                    <small className="text-danger">{errors?.country?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Landmark")}</label>
                                <input
                                    className="form-control"
                                    {...register('other_address_details')}
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Set As Default")}<span className="fw-bold text-danger">*</span></label>
                                <select
                                    className="form-select ms-0"
                                    {...register('default_address', {
                                        required: (t("*required"))
                                    })}
                                >
                                    <option value={''}>--Select--</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors?.default_address &&
                                    <small className="text-danger">{errors?.default_address?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col xs={12} className="pb-3 d-flex justify-content-center">
                            <Button size="md" onClick={handleSubmit(onSubmit)}>
                                {t("Add Address")}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
    )
};
