import React from "react";
import centercard2image from "../../assets/images/bannercardimages/Treatmentbanner.webp";
import centercard1image from "../../assets/images/bannercardimages/treatmentbanner-2.webp";
import centercard3image from "../../assets/images/bannercardimages/treatmentbanner-3.webp";
import treatmentcardimage1 from "../../assets/images/image 41.png";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { LogEvent } from "../../utils/firebase";
import feedingBanner from '../../assets/images/feeding_banner_mobile.png'
import feedingDoctor1 from '../../assets/images/feeding_doctor_image_1.png'
import feedingDoctor2 from '../../assets/images/feeding_doctor_image_2.png'
import feeding_full_banner1 from '../../assets/images/feeding_management_full_image.png'
import feeding_full_banner3 from '../../assets/images/feeding_full_image3.png'
import feeding_full_banner1_tamil from '../../assets/images/feeding_full_banner1_tamil.svg'
import feeding_full_banner1_marati from '../../assets/images/feeding_full_banner1_marati.svg'
import feeding_full_banner1_svg from '../../assets/images/feeding_full_banner1_svg.svg'
import feeding_full_banner3_tamil from '../../assets/images/feeding_management_banner3_tamil.svg'
import feeding_full_banner3_marati from '../../assets/images/feeding_management_banner3_marati.svg'
import feeding_full_banner3_svg from '../../assets/images/feeding_management_banner3.svg'

import increase_milk_banner_english from '../../assets/images/Increase Milk Product - English.png'
import increase_milk_banner_tamil from '../../assets/images/Increase milk production - Tamil.png'
import increase_milk_banner_marati from '../../assets/images/Increase milk production - Marathi.png'
import download_yourFarm_english from '../../assets/images/Download yourfarm app - English.png'
import download_yourFarm_tamil from '../../assets/images/Download yourfarm app -Tamil.png'
import download_yourFarm_marati from '../../assets/images/Download yourfarm app -Marathi.png'
import free_doctor_english from '../../assets/images/Free Doctor Consultation - English.png'
import free_doctor_tamil from '../../assets/images/Free Doctor Consultation - Tamil.png'
import free_doctor_marati from '../../assets/images/Free Doctor Consultation - Marathi.png' 

const TreatmentCard = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language



  // BANNER 1
  const getImageSource1 = () => {
    if (i18n.language === 'tm') { 
      return increase_milk_banner_tamil;
    } else if (i18n.language === 'mr') { 
      return increase_milk_banner_marati; 
    } else {
      return increase_milk_banner_english; 
    }
  };

  

  // BANNER 2
  const getImageSource2 = () => {
    if (i18n.language === 'tm') { 
      return download_yourFarm_tamil;
    } else if (i18n.language === 'mr') { 
      return download_yourFarm_marati; 
    } else {
      return download_yourFarm_english; 
    }
  };


  // BANNER 3
  const getImageSource3 = () => {
    if (i18n.language === 'tm') {
      return free_doctor_tamil;
    } else if (i18n.language === 'mr') {
      return free_doctor_marati; 
    } else {
      return free_doctor_english; 
    }
  };


  const getTopPosition1 = () => {
    if(i18n.language === 'tm'){
      return '67%'
    }
    if (i18n.language === 'mr') {
      return '65%'; 
    }
    return '66%'; 
  };

  const getLeft1 = () => {
    if (i18n.language === 'tm'){
      return '16%'
    }
    if (i18n.language === 'mr') {
      return '13%'; 
    }
    return '12%'; 
  };

  const getWidth1 = () => {
    if (i18n.language === 'tm') {
      return '22%'; 
    }
    if (i18n.language === 'mr'){
      return '16%'
    }
    return '14%'; 
  };

  const getTopPosition2 = () => {
    if (i18n.language === 'tm') {
      return '67%'; 
    }
    if (i18n.language === 'mr'){
      return '64%'
    }
    return '67%'; 
  };

  const getLeft2 = () => {
    if (i18n.language === 'tm') {
      return '15%'; 
    }
    if (i18n.language === 'mr'){
      return '14%'
    }
    return '14%'; 
  };

  const getWidth2 = () => {
    if (i18n.language === 'tm') {
      return '20%'; 
    }
    if (i18n.language === 'mr'){
      return '18%'
    }
    return '18%'; 
  };


  const getTopPosition3 = () => {
    if (i18n.language === 'tm') {
      return '68%'; 
    }
    if (i18n.language === 'mr'){
      return '65%'
    }
    return '66%'; 
  };

  const getLeft3 = () => {
    if (i18n.language === 'tm') {
      return '16%'; 
    }
    if (i18n.language === 'mr'){
      return '13%'
    }
    return '12%'; 
  };

  const getWidth3 = () => {
    if (i18n.language === 'tm') {
      return '22%'; 
    }
    if (i18n.language === 'mr'){
      return '16%'
    }
    return '14%'; 
  };
  


  return (
    <>
    {/* <Container>
      <Carousel controls={false} style={{ background: "none" }} interval={3000}>
        <Carousel.Item>
          <div className="container">
            <div className="treatment-center-card bg-light-subtle mt-4">
              <div className="center-card-body d-flex align-items-center">
                <div className="position-relative w-100">
                  <div className="positioned-content">
                    <h5 className="card-title fw-semi-bold center-card-title custom-color">
                      {t("Increase Milk Production")}
                    </h5>
                    <p className="white-text responsive-text mt-2">
                      {t(
                        "Know your animals feed requirement with our expert veterinary doctor and increase milk production upto 200 liters per lactation"
                      )}
                    </p>
                    <div>
                      <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
                    </div>
                  </div>
                  <img
                    src={centercard1image}
                    className="banner-img-fluid"
                    alt="Veterinary Service Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          
          <div className="container">
            <div className="treatment-center-card bg-light-subtle mt-4">
              <div className="center-card-body d-flex align-items-center">
                <div className="position-relative w-100">
                  <div className="positioned-content">
                    <h5 className="card-title fw-semi-bold center-card-title custom-color">
                      {t("Download YourFarm app")}
                    </h5>
                    <p className="white-text responsive-text mt-2">
                      {t(
                        "Introducing world's first end to end animal healthcare platform. Disease diagnosis, ethnoveterinary treatment solutions and followup till recovery from illness"
                      )}
                    </p>
                    <div>
                      <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
                    </div>
                  </div>
                  <img
                    src={centercard2image}
                    className="banner-img-fluid"
                    alt="Veterinary Service Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          
          <div className="container">
            <div className="treatment-center-card bg-light-subtle mt-4">
              <div className="center-card-body d-flex align-items-center">
                <div className="position-relative w-100">
                  <div className="positioned-content">
                    <h5 className="card-title fw-semi-bold center-card-title custom-color">
                      {t("Free Doctor consultation")}
                    </h5>
                    <p className="white-text responsive-text mt-2">
                      {t(
                        "Discover our comprehensive healthcare services for your animals: Connect with professional veterinary doctors, diagnose your animal diseases and get immediate solution for quick recovery from illness"
                      )}
                    </p>
                    <div>
                      <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
                    </div>
                  </div>
                  <img
                    src={centercard3image}
                    className="banner-img-fluid"
                    alt="Veterinary Service Image"
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </Container> */}

    {/* <Container style={{marginTop: '40px'}}>
        <Carousel controls={false} interval={null} style={{background: 'none', padding: '0px', opacity: 'initial'}}>


        <Carousel.Item style={{borderRadius: '20px'}} className="new_car2">
          
          <Row >
            <Col lg={7} md={6} style={{padding: '45px'}}>
            <h1 className="cars_head_1" style={{color: '#DC7F92'}}>{t('Increase Milk Production')}</h1>
            <p className="cars_para_1" style={{color: 'white'}}>{t('Know your animals feed requirement with our expert veterinary doctor and increase milk production upto 200 liters per lactation')}</p>
            <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
            </Col>
                      
            
            <Col lg={5} md={6} style={{display: 'flex', justifyContent: 'center'}}>
            <img style={{width: '135%', height: '102%', marginTop: '-3px'}} src={feedingDoctor2} alt="" />
            
            </Col>
          </Row>
        </Carousel.Item>

          <Carousel.Item style={{borderRadius: '20px'}} className="new_car">
          
            <Row>
              <Col lg={7} md={6} sm={6} style={{padding: '45px'}}>
              <h1 className="cars_head_1" style={{color: '#DC7F92'}}>{t('Download YourFarm app')}</h1>
              <p className="cars_para_3" style={{color: 'white'}}>{t("Introducing world's first end to end animal healthcare platform. Disease diagnosis, ethnoveterinary treatment solutions and followup till recovery from illness")}</p>
              <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
              </Col>
                        
              
              <Col lg={5} md={6} sm={6} style={{display: 'flex', justifyContent: 'center'}}>
              <img style={{width: '45%'}} src={feedingBanner} alt="" />
              </Col>
            </Row>
          </Carousel.Item>


          <Carousel.Item style={{borderRadius: '20px'}} className="new_car1">
          
            <Row >
              <Col style={{padding: '45px'}} lg={7} md={6} >
              <h1 className="cars_head_1" style={{color: '#DC7F92'}}>{t('Free Doctor consultation')}</h1>
              <p className="cars_para_3" style={{color: 'white'}}>{t('Discover our comprehensive healthcare services for your animals: Connect with professional veterinary doctors, diagnose your animal diseases and get immediate solution for quick recovery from illness')}</p>
              <button className="Team-center-card-button d-flex justify-content-center">
                        <div className="Team-Icon">
                          <FaPhoneAlt alt="Custom Icon" />
                        </div>
                        <span
                          onClick={() => {
                            const phoneNumber = DecryptFunction(
                              sessionStorage.getItem("pilot_no")
                            );
                            LogEvent("Floating_Call_Button_Clicked");
                            window.location.href = `tel:${phoneNumber}`;
                          }}
                        >
                          {t('Call Doctor')}
                        </span>
                      </button>
              </Col>
                        
              
              <Col lg={5} md={6}  style={{display: 'flex', justifyContent: 'center'}}>
              <img style={{width: '100%', height: '102%', marginTop: '-2px'}} src={feedingDoctor1} alt="" />
              
              </Col>
            </Row>
          </Carousel.Item>




          
        </Carousel>
    </Container> */}

    <Container style={{paddingTop: '20px', paddingBottom: '20px'}}>
      <Carousel className="feeding_full" controls={false}   interval={null}>


        {/* BANNER 1 */}
        <Carousel.Item >
          <div style={{position: 'relative'}}>
        <img  style={{width: '100%'}} src={getImageSource1()} alt="" />
        <div
              
              style={{
                color: 'white',
                position: 'absolute',
                top: getTopPosition1(), 
                left: getLeft1(),
                fontSize: '2vw',
                transform: 'translateX(-50%)', 
                width: getWidth1(), 
                height: '13%', 
                backgroundColor: 'rgba(0, 0, 0, 0)', 
                cursor: 'pointer', 
              }}
              onClick={() => {
                const phoneNumber = DecryptFunction(
                  sessionStorage.getItem("pilot_no")
                );
                LogEvent("Floating_Call_Button_Clicked");
                window.location.href = `tel:${phoneNumber}`;
                
              }}
            ></div>
        </div>
        
        </Carousel.Item>


        {/* BANNER 2 */}
        <Carousel.Item>
        <div style={{position: 'relative'}}>
        <img  style={{width: '100%'}} src={getImageSource2()} alt="" />
        <div
              
              style={{
                color: 'white',
                position: 'absolute',
                top: getTopPosition2(), // Adjust the vertical position of the button (relative to the image)
                left: getLeft2(),
                fontSize: '2vw',
                transform: 'translateX(-50%)', // Center the button horizontally
                width: getWidth2(), // Width of the clickable area
                height: '14%', // Height of the clickable area
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
                cursor: 'pointer', // Make it look clickable
              }}
              onClick={() => {
                const phoneNumber = DecryptFunction(
                  sessionStorage.getItem("pilot_no")
                );
                LogEvent("Floating_Call_Button_Clicked");
                window.location.href = `tel:${phoneNumber}`;
                
              }}
            ></div>
        </div>
        </Carousel.Item>



        {/* BANNER 3 */}
        <Carousel.Item>
        <div style={{position: 'relative'}}>
        <img  style={{width: '100%'}} src={getImageSource3()} alt="" />
        <div
              
              style={{
                color: 'white',
                position: 'absolute',
                top: getTopPosition3(), // Adjust the vertical position of the button (relative to the image)
                left: getLeft3(),
                fontSize: '2vw',
                transform: 'translateX(-50%)', // Center the button horizontally
                width: getWidth3(), // Width of the clickable area
                height: '14%', // Height of the clickable area
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
                cursor: 'pointer', // Make it look clickable
              }}
              onClick={() => {
                const phoneNumber = DecryptFunction(
                  sessionStorage.getItem("pilot_no")
                );
                LogEvent("Floating_Call_Button_Clicked");
                window.location.href = `tel:${phoneNumber}`;
                
              }}
            ></div>
        </div>
        </Carousel.Item>
      </Carousel>
      
    </Container>
    </>
  );
};

export default TreatmentCard;
