import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import './Demo.css'
import { useSelector } from 'react-redux';
import demoImage from '../assets/images/feeding_full_banner1_svg.svg'

const Demo = () => {

  const [blogList, setBlogList] = useState([]);
  const [totalSlides, setTotalSlides] = useState(0);
  const { homeContent } = useSelector(state => state.AuthReducer);


  useEffect(() => {
    if (homeContent?.length > 0) {
      const filteredBlogs = homeContent.filter(val => val?.type === 'blog').slice(3, 10);
      setBlogList(filteredBlogs);
      setTotalSlides(filteredBlogs.length);
    }
  }, []);

  const settings = {
    centerMode: true,      // Enable center mode
    infinite: true,        // Infinite loop
    centerPadding: '0',  
    slidesToShow: 3,       // Show 3 slides at once
    speed: 500,            // Transition speed
    focusOnSelect: true,   // Allow selecting slides
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        }
      }
    ]
  };
  return (
    <>
    

  
    <div className="carousel-container" style={{marginTop: '500px'}}>
      
      <Slider {...settings} className='hello'>

        <div className="slide">
          <img style={{width: '100%'}} src={demoImage} alt="" />
        </div>
        <div className="slide">
          <h3>Slide 2</h3>
        </div>
        <div className="slide">
          <h3>Slide 3</h3>
        </div>
        <div className="slide">
          <h3>Slide 4</h3>
        </div>
        <div className="slide">
          <h3>Slide 5</h3>
        </div>
      </Slider>
    </div>
  

    </>
    
  )
}

export default Demo