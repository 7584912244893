import React from 'react';
import treatmentcardimage1 from "../../../assets/images/our_team_full_banner.png"
import ourTeamImage from '../../../assets/images/our_team_card_image.png'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { IoCall } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from '../../../utils/cryptoFunction';
import { LogEvent } from '../../../utils/firebase';
import bannerTamil from '../../../assets/images/yourFarm_mission_tamil.png'
import bannerMarati from '../../../assets/images/yourFarm_mission_marati.png'
const TreatmentCard = () => {
    const { t, i18n } = useTranslation();

    const getOurTeamImageSource = () => {
        if (i18n.language === 'tm') { // Tamil
          return bannerTamil; // Tamil Image Source
        } else if (i18n.language === 'mr') { // Marathi
          return bannerMarati; // Marathi Image Source
        } else {
          return treatmentcardimage1; // Default English Image Source
        }
      };

    return (
        <>
         {/* <div className="background-custom-color">
        //     <Container>
        //         <div className="container">
        //             <div className="center-card-body d-flex align-items-center">
        //                 <div className="position-relative w-100">
        //                     <div className="positioned-content">
        //                         <h5 className="card-title fw-semi-bold center-card-title custom-color">{t('YourFarm Vision & Mission')}</h5>
        //                         <p className="white-text responsive-text mt-2">{t('Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
        //                     </div>
        //                     <img src={treatmentcardimage1} className="banner-img-fluid-ourteam" alt="Veterinary Service Image" />
        //                 </div>
        //             </div>
                    
        //         </div>
        //     </Container>
         </div> */}


{/* <Container fluid style={{ backgroundColor: '#FFD1DA', height: '239px', position: 'relative' }}>
    <Container
        className="our_team_banner_overlay"
        style={{
            backgroundColor: '#0D1560',
            borderRadius: '18px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -15%)', 
            
        }}
    >
        <Row>
            <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{marginLeft: '40px'}}>
                <h1 style={{ color: '#dc7f92' }} className='cars_head_1'>{t('YourFarm Vision & Mission')}</h1>
                <p style={{ color: 'white' }} className='cars_para_1'>{t('Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
            </div>
            </Col>

            <Col>
                
                <img
                    className="our_team_gradient_back"
                    style={{ width: '100%', height: '100%' }}
                    src={ourTeamImage}
                    alt=""
                />
            </Col>
        </Row>
    </Container>
</Container> */}


<Container fluid className='our_team_background' style={{ backgroundColor: '#FFD1DA', height: '17vw', position: 'relative' }}>
    <Container
        className="our_team_banner_overlay"
        style={{
            borderRadius: '18px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -20%)', 
            
        }}
    >
        <img style={{width: '100%'}} src={getOurTeamImageSource()} alt="" />
    </Container>
</Container>



        </>
    )
}

export default TreatmentCard
