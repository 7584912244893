import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import SidebarFilters from "./sidebarFilters";
import { FiGrid, FiFilter } from "react-icons/fi";
import { FaListUl } from "react-icons/fa";
import ProductGridView from "./productGridView";
import { useDispatch, useSelector } from "react-redux";
import ProductListView from "./productListView";
import Pagination from "../../common/pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import productActions from "../../Redux/Products/action";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import cartActions from "../../Redux/cart/actions";
import { useTranslation } from "react-i18next";
import { CgSearch } from "react-icons/cg";
import { priceRoundOff } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";

export default function ProductsIndex() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation()
  const category = searchParams.get("category");

  const { currentLanguage } = useSelector((state) => state.commonReducer);

  const { products, selectedProductFilter } = useSelector(
    (state) => state.ProductListReducer
  );
  const { userAddress } = useSelector((state) => state.userReducer);

  const [view, setView] = useState("list");
  const [productList, setProductList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);

  // cart items
  const { cartProducts } = useSelector((state) => state.cartReducer);

  useEffect(() => {
    if (category === "feedsandsupplement") {
      dispatch({
        type: productActions.GET_PRODUCT_LIST_BY_CATEGORY,
        payload: "supplement",
      });
    } else {
      dispatch({
        type: productActions.GET_PRODUCT_LIST_BY_CATEGORY,
        payload: category ?? "herbal",
      });
    }
    dispatch({ type: productActions.GET_PRODUCT_FILTER });
  }, [category]);

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("auth_user")) {
      const decryptedId = DecryptFunction(localStorage.getItem("auth_user"));
      if (decryptedId) {
        dispatch({
          type: userActions.GET_USER_ADDRESS,
          payload: { id: decryptedId },
        });
      }
    }
  }, []);

  const [currentItems, setCurrentItems] = useState([]);


  useEffect(() => {
    if (products?.length > 0) {
      setProductList(
        products?.map((val) => {
          let finalLanguage = val?.language?.find(
            (lang) => lang?.language_code === currentLanguage
          );
          return { ...val, quantity: 1, finalLanguage: finalLanguage };
        })
      );
      setCurrentItems(productList?.slice(itemOffset, endOffset));
    }
  }, [currentLanguage, products, currentItems]);
  
  const endOffset = itemOffset + itemsPerPage;
  //   const currentItems = productList?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(productList?.length / itemsPerPage);
  console.log(productList, currentItems,  "currentItems");

  const handlePageChange = (event) => {
    const newOffset = (event.selected * itemsPerPage) % productList?.length;
    setItemOffset(newOffset);
    setCurrentPage(event?.selected);
    window.scrollTo({ top: 0 });
  };

  const onChangeView = (newView) => {
    if (newView === "grid") {
      LogEvent("Products_GridView_Clicked");
    } else {
      LogEvent("Products_ListView_Clicked");
    }
    setView(newView);
  };

  const handleCloseModal = () => {
    setShowFilterModal(false);
  };

  const handleViewDetails = (e, id, value) => {
    e.preventDefault();
    LogEvent(`${value?.medicine_name}`);
    const encrypt = EncryptFunction(id);
    if (encrypt) {
      navigate(`/product-details?id=${encrypt}&avg=${value.average_rating}`, {
        state: value,
      });
    }
  };

  const handleAddToCart = (e, value) => {
    e.preventDefault();
    LogEvent("Products_AddToCart");
    let discountValue = 0,
      qty = parseInt(value?.quantity || 1);
    if (value?.discount_status === 1 && value.discount_amount > 0) {
      if (value?.discount_type === 1) {
        discountValue = (value?.amount * value?.discount_amount) / 100;
      } else {
        discountValue = value?.discount_amount;
      }
    }
    dispatch({
      type: cartActions.CURRENT_ADDING_CART_PRODUCT,
      payload: {
        order_items: [
          {
            product_id: value?.id,
            rate: value?.amount,
            quantity: qty,
            name: value?.language?.find(
              (lang) => lang?.language_code === currentLanguage
            )?.medicine_name,
            // "name": value?.medicine_name,
            discount: priceRoundOff(discountValue),
            zoho_item_id: 0,
            gst: value?.gst,
            coupon_discount: 0,
          },
        ],
      },
    });
    if (localStorage.getItem("token")) {
      if (userAddress?.length === 0) {
        sessionStorage.setItem("addtocart", "Address");
        dispatch({
          type: commonActions.SET_SHOW_USER_ADDRESS_MODAL,
          payload: true,
        });
      } else {
        dispatch({
          type: cartActions.ADD_PRODUCT_TO_CART,
          cattleType: value.cattle_type,
        });
      }
    } else {
      sessionStorage.setItem("addtocart", "Login");
      dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
    }
  };

  const handleQtyValue = (e, value, i) => {
    let list = [...productList];
    list[i].quantity = e.target.value;
    setProductList(list);
  };

  const handleInputSearch = (e) => {
    dispatch({
      type: productActions.SELECTED_PRODUCT_FILTER,
      payload: {
        ...selectedProductFilter,
        searchedText: e.target.value.toLowerCase(),
      },
    });
    dispatch({
      type: productActions.PRODUCT_FILTER,
      payload: {
        ...selectedProductFilter,
        searchedText: e.target.value.toLowerCase(),
      },
    });
  };

  return (
    <Container className="my-5" style={{minHeight:'calc(89vh)'}}>
      <Row>
        <Col md={12} className="market_place_title_main_col">
          <div className="page_heading_div_cls-product_List">
            {category === "herbal"
              ? `${t("Herbal Products")}`
              : category === "feedsandsupplement"
              ? `${t("Feeds and Supplements")}`
              : `${t("Farm Equipments")}`}
          </div>
          <div className="home-search-div">
            <input
              type="search"
              className="form-control home-search"
              placeholder={t("Search")}
              defaultValue={selectedProductFilter?.searchedText || ""}
              onChange={handleInputSearch}
            />
            <CgSearch className="home-search-icon" />
          </div>
        </Col>
        <Col lg={4} className="market_place_bottom_content_col">
          <div className="big-screen-filter-sidebar">
            <SidebarFilters category={category ?? "herbal"} />
          </div>
        </Col>
        <Col
          lg={8}
          md={12}
          className="scrollable-column market_place_bottom_content_col"
        >
          <Row className="row-padding-remover">
            <Col
              md={12}
              xs={12}
              className="d-flex justify-content-end product_list_view_icons_column_cls"
            >
              <div
                className="small-screen-filter-modal"
                onClick={() => setShowFilterModal(true)}
              >
                <FiFilter size={20} />
              </div>
              <div className="ms-3 view-icon-none" role="group">
                <FiGrid
                  size={20}
                  className={`me-1 cursor-pointer ${
                    view === "grid" ? "product-card-active" : "product-card"
                  }`}
                  onClick={() => onChangeView("grid")}
                />
                <FaListUl
                  size={20}
                  className={`ms-1 cursor-pointer ${
                    view === "list" ? "product-card-active" : "product-card"
                  }`}
                  onClick={() => onChangeView("list")}
                />
              </div>
            </Col>
            {view === "grid" && (
              <ProductGridView
                Products={currentItems}
                handleViewDetails={handleViewDetails}
                handleAddToCart={handleAddToCart}
                // handleAddToCart={removeAllCartItemsAndAdd}
                handleQtyValue={handleQtyValue}
                category={category ?? "herbal"}
              />
            )}
            {view === "list" && (
              <ProductListView
                Products={currentItems}
                handleViewDetails={handleViewDetails}
                handleAddToCart={handleAddToCart}
                // handleAddToCart={removeAllCartItemsAndAdd}
                handleQtyValue={handleQtyValue}
                category={category ?? "herbal"}
              />
            )}
            {pageCount > 1 && currentItems?.length > 1 && (
              <div className="mt-4">
                <Pagination
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </Row>
        </Col>
      </Row>
      <Modal
        dialogClassName="product-filter-modal"
        show={showFilterModal}
        onHide={handleCloseModal}
        centered
      >
        <div className="modal_close_icons" onClick={handleCloseModal}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </div>
        <Container className="small-screen-filter-cantainer">
          <SidebarFilters handleCloseModal={handleCloseModal} />
        </Container>
      </Modal>
    </Container>
  );
}
