import i18n from "../../langugage/en.json"
export const serverDataLanguage = (resData, data) => {
  return resData.map((item) => {
    const { language, service_lang, provider_lang, category_lang, ...rest } =
      item;

    let translatedObject = language?.find(
      (translationObj) => translationObj.language_code === i18n.language
    );
    const translatedObject2 = service_lang?.find(
      (translationObj) => translationObj.language_code === i18n.language
    );
    const translatedObject3 = provider_lang?.find(
      (translationObj) => translationObj.language_code === i18n.language
    );
    const translatedObject4 = category_lang?.find(
      (translationObj) => translationObj.language_code === i18n.language
    );
    return {
      ...rest,
      ...translatedObject,
      ...translatedObject2,
      ...translatedObject3,
      ...translatedObject4,
      ...data,
    };
  });
};
