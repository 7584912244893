import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useTranslation } from "react-i18next";
import homeActions from "../../../Redux/Home/action";
import { useDispatch, useSelector } from "react-redux";
import { serverDataLanguage } from "../../ServerDataLanguage";
import styled from "styled-components";

const Farmertag = (props) => {
  const { fieldAcitvity } = props;
  const { t } = useTranslation();
  const { teamContent } = useSelector((state) => state.AuthReducer);
  const serverData = serverDataLanguage(teamContent);
  const dispatch = useDispatch();

  let [fieldAcitvities, setFieldActivites] = useState([]);
  let [hover, sethover] = useState(null);

  useEffect(() => {
    dispatch({
      type: homeActions.GET_TEAM_CONTENTS,
      payload: fieldAcitvity,
    });
  }, [dispatch]);

  useEffect(() => {
    setFieldActivites(() =>
      serverData?.filter((val) => {
        if (val?.type.toLowerCase() === fieldAcitvity) {
          return val;
        }
      })
    );
  }, [teamContent]);

  const handleHover = (e) => {
    sethover(e.target.id);
  };

  const Clamptext = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    lineheight: 14.2em;
    fontsize: 14px;
    fontweight: 500;
    color: #232c63;
    padding: 5px 15px 0px 15px;

    //   &:hover {
    //     /* Change color when hovered */
    // }
  `;

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    rtl: true, // Enable right-to-left sliding
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Carousel controls={false} interval={null}>
      <Carousel.Item>
        <div className="container-fluid" style={{ backgroundColor: "#FFF3F5", padding: "2rem 0" }}>
          <div className="container">
            <div className="New_achievement_div_cls">
              {t("Our Field Activities")}
            </div>
            <div className="treatment-page-container">
              <Slider {...settings}>
                {fieldAcitvities.map((product) => (
                  <div key={product.id} className="activity_main_div_position" style={{position: 'relative'}}>
                    <img
                      style={Styles.img}
                      className="Team-Grid-img"
                      src={product.img_url}
                      alt={`Product ${product.id}`}
                      onClick={() => product.link}
                    />
                    <div style={{position: 'absolute'}}
                    className={hover == product.id ?"activity-hover":"activity-shortDiscrip"}
                      // style={
                      //   hover == product.id ? Styles.hover : Styles.shortDiscrip
                      // }
                      id={product.id}
                      onMouseOver={handleHover}
                      onMouseOut={() => sethover(null)}
                      onClickCapture={() =>
                        window.open(product.link, "_blank", "noreferrer")
                      }
                    >
                      <div
                        // className="treatment-page-container"
                        className="activity-ShortTitle"
                        id={product.id}
                        onMouseOver={handleHover}
                        onMouseOut={() => sethover(null)}
                      >
                        {product.title}
                      </div>
                      <div className="activity-descripDiv">
                        {product?.contents.map((item) =>
                          hover == product.id ? (
                            <Clamptext
                              id={product.id}
                              onMouseEnter={handleHover}
                              // onMouseOver={handleHover(product.id)}
                              onMouseOut={() => {
                                sethover(null);
                              }}
                            >
                              {item?.descirption}
                            </Clamptext>
                          ) : null
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

const Styles = {

  shortDiscrip: {
    position: "absolute",
    cursor: "pointer",
    top: "12.9rem",
    zIndex: 100,
    height: "5rem",
    width: "22.1rem",
    overflow: "hidden",
    backgroundColor: "#ededed",
    opacity: 1,
    borderColor: "#000000",
    borderWidth: "5px",
    borderRadius: "0px 0px 12px 12px ",
  },
  hover: {
    position: "absolute",
    cursor: "pointer",
    top: "11.3rem",
    zIndex: 100,
    height: "6.7rem",
    width: "22.1rem",
    overflow: "hidden",
    backgroundColor: "#ededed",
    opacity: 0.85,
    borderRadius: "14px 14px 14px 14px ",
  },
  ShortTitle: {
    fontSize: "18px",
    lineheight: "36px",
    fontWeight: 400,
    color: "#232C63",
    padding: "5px 0px 0px 15px",
  },
  hoverShortTitle: {
    fontSize: "18px",
    lineheight: "36px",
    fontWeight: 400,
    opacity: "100%",
    color: "#232C63",
    padding: "5px 0px 0px 15px",
  },
  descripDiv: {
    // padding: '2rem',
    margin: "0rem 0.5rem 0rem 0.2rem",
  },
  Discrip: {
    height: "2.5em",
    maxheight: "15em",
    lineheight: "14.2em",
    fontSize: "14px",
    fontWeight: 500,
    color: "#232C63",
    opacity: 0,
    padding: "0px 0px 0px 15px",
    overflow: "hidden",
    textoverflow: "ellipsis",
  },
  hover_Discrip: {
    maxheight: "21rem",
    lineheight: "14.2em",
    fontSize: "14px",
    fontWeight: 500,
    color: "#232C63",
    padding: "5px 15px 0px 15px",
    height: "70%",
    overflow: "hidden",
    display: "inline-block",
    textoverflow: "ellipsis",
    whitespace: "nowrap",
  },
};
export default Farmertag;