// import actions from "./action";

// const initState = {
//     MobileOTP: [],
//     VerifyOTP:[],
//     UserProfile:[],
//     ProductsHome:[],
//     StoreUserId:[],
//     UserFarmPlace:[],
//     homeResponse: null,
//     homeContent: [],
//     UpdateUserProfile: [],
//     homeMarketplaceCattle: [],
//     PrivacyPolicy:[],
// }

// const AuthReducer = (state = initState, action) => {
//     switch (action.type) {
//         case actions.SEND_OTP:
//             {
//                 return {
//                     ...state,
//                     MobileOTP: action.payload
//                 }
//             }
//             case actions.VERIFY_OTP:
//                 return {
//                     ...state,
//                     VerifyOTP: action.payload
//                 }
//             case actions.SET_USER_PROFILE:
//                 return {
//                     ...state,
//                     UserProfile: action.payload
//                 }
//             case actions.SET_USER_UPDATE_PROFILE:
//                 return {
//                     ...state,
//                     UpdateUserProfile: action.payload
//                 }
//             case actions.SET_PRODUCTS_HOME:
//                 return {
//                     ...state,
//                     ProductsHome: action.payload
//                 }
//             case actions.STORE_ID_ACTION:
//                 return {
//                     ...state,
//                     StoreUserId: action.payload
//                 }
//             case actions.HOME_RESPONSE:
//                 return {
//                     ...state,
//                     homeResponse: action.payload
//                 }
//             case actions.SET_HOME_CONTENTS:
//                 return {
//                     ...state,
//                     homeContent: action.payload
//                 }
//             case actions.SET_USER_FARM_PLACE:
//                 return {
//                     ...state,
//                     UserFarmPlace: action.payload
//                 }
//             case actions.SET_HOME_MARKETPLACE_CATTLE_LIST:
//                 return {
//                     ...state,
//                     homeMarketplaceCattle: action.payload
//                 }
//             case actions.SET_PRIVACY_POLICY:
//                 return {
//                     ...state,
//                     PrivacyPolicy: action.payload
//                 }
//         default: return state;
//     }
// }
// export default AuthReducer;
import actions from "./action";

const initState = {
  MobileOTP: [],
  VerifyOTP: [],
  UserProfile: [],
  ProductsHome: [],
  StoreUserId: [],
  UserFarmPlace: [],
  homeResponse: null,
  homeContent: [],
  teamContent: [],
  UpdateUserProfile: [],
  homeMarketplaceCattle: [],
  PrivacyPolicy: [],
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SEND_OTP: {
      return {
        ...state,
        MobileOTP: action.payload,
      };
    }
    case actions.VERIFY_OTP:
      return {
        ...state,
        VerifyOTP: action.payload,
      };
    case actions.SET_USER_PROFILE:
      return {
        ...state,
        UserProfile: action.payload,
      };
    case actions.SET_USER_UPDATE_PROFILE:
      return {
        ...state,
        UpdateUserProfile: action.payload,
      };
    case actions.SET_PRODUCTS_HOME:
      return {
        ...state,
        ProductsHome: action.payload,
      };
    case actions.STORE_ID_ACTION:
      return {
        ...state,
        StoreUserId: action.payload,
      };
    case actions.HOME_RESPONSE:
      return {
        ...state,
        homeResponse: action.payload,
      };
    case actions.SET_HOME_CONTENTS:
      return {
        ...state,
        homeContent: action.payload,
      };
    case actions.SET_TEAM_CONTENTS:
      return {
        ...state,
        teamContent: action.payload,
      };
    case actions.SET_USER_FARM_PLACE:
      return {
        ...state,
        UserFarmPlace: action.payload,
      };
    case actions.SET_HOME_MARKETPLACE_CATTLE_LIST:
      return {
        ...state,
        homeMarketplaceCattle: action.payload,
      };
    case actions.SET_PRIVACY_POLICY:
      return {
        ...state,
        PrivacyPolicy: action.payload,
      };
    default:
      return state;
  }
};
export default AuthReducer;
