// import React from 'react';
// import { Carousel } from 'react-bootstrap';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// import krishi1 from "../../../assets/images/consulation/Achievements section/Krishi mangal.png";
// import krishi2 from "../../../assets/images/consulation/Achievements section/Krishi mangal 2.png";
// import Krishi3 from "../../../assets/images/consulation/Achievements section/Krishi mangal 3.png";
// import pitchanthon1 from "../../../assets/images/consulation/Achievements section/Pitchanthon.png";
// import pitchanthon2 from "../../../assets/images/consulation/Achievements section/Pichathon 2.png";
// import pitchanthon3 from "../../../assets/images/consulation/Achievements section/Pichathon 3.png";
// import pitchanthon4 from "../../../assets/images/consulation/Achievements section/Pichathon 4.png";
// import labs from "../../../assets/images/consulation/Achievements section/Labs.png";
// import indianinstitue from "../../../assets/images/consulation/Achievements section/Indian institute.png";
// import indiaagrite from "../../../assets/images/consulation/Achievements section/India agrite.png";
// import img142 from "../../../assets/images/consulation/Achievements section/1709485160135 2.png";
// import frame078 from "../../../assets/images/consulation/Achievements section/1709485161561 2.png";
// import frame648 from "../../../assets/images/consulation/Achievements section/2037861648.png";
// import frame652 from "../../../assets/images/consulation/Achievements section/Frame 1410265078.png";
// import frame216 from "../../../assets/images/consulation/Achievements section/IMG-20240228-WA0014 2.png";
// import frame135 from "../../../assets/images/consulation/Achievements section/IMG-20240316-WA0024 1.png";
// import frame1561 from "../../../assets/images/consulation/Achievements section/Headstart.png";
// import ET from "../../../assets/images/consulation/Achievements section/ET.png";
// import cohort from "../../../assets/images/consulation/Achievements section/Cohort.png";
// import biracs from "../../../assets/images/consulation/Achievements section/Birac's.png";
// import AgriBusiness from "../../../assets/images/consulation/Achievements section/Agri business.png";
// // import Actionforindia from "../../../assets/images/consulation/Achievements section/Action for india.png";

// const TreatmentDocPage = () => {
//   const { t } = useTranslation();

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 800,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 4000,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const products = [
//     { id: 1, image_url: krishi1, link: "https://www.linkedin.com/posts/social-alpha_krishi-mangal-20-ugcPost-7203723420562259968-NRGj?utm_source=share&utm_medium=member_desktop" },
//     { id: 2, image_url: krishi2, link: "https://www.linkedin.com/posts/social-alpha_krishi-mangal-20-ugcPost-7203723420562259968-NRGj/?utm_source=share&utm_medium=member_desktop" },
//     { id: 3, image_url: Krishi3, link: "https://www.linkedin.com/posts/social-alpha_krishi-mangal-ugcPost-7211340681577947138-uhF6/?utm_source=share&utm_medium=member_desktop" },
//     { id: 4, image_url: pitchanthon1, link: "https://www.linkedin.com/posts/yourfarm-agritech_animeta-headstart-bharathpichathon-a[…]312999739392-byvW" },
//     { id: 5, image_url: pitchanthon2, link: "https://www.linkedin.com/posts/yourfarm-agritech_animeta-headstart-bharathpichathon-a[…]312999739392-byvW" },
//     { id: 6, image_url: pitchanthon3, link: "https://www.linkedin.com/posts/yourfarm-agritech_animeta-headstart-bharathpichathon-a[…]312999739392-byvW" },
//     { id: 7, image_url: pitchanthon4, link: "https://www.linkedin.com/posts/yourfarm-agritech_animeta-headstart-bharathpichathon-a[…]312999739392-byvW" },
//     { id: 8, image_url: labs },
//     { id: 9, image_url: indianinstitue },
//     { id: 10, image_url: indiaagrite, link: "https://www.linkedin.com/posts/indigram-labs-foundation_itc-birac-bionest-ugcPost-721[…]695746727936-6YbY?utm_source=share&utm_medium=member_desktop" },
//     { id: 12, image_url: img142, link: "https://www.linkedin.com/posts/dr-vijayakumar-ramalingam-13990596_tieconchd2024-tieco[…]825617604608-CiRX?utm_source=share&utm_medium=member_desktop" },
//     { id: 13, image_url: frame078 },
//     { id: 14, image_url: frame648 },
//     { id: 15, image_url: frame652 },
//     { id: 16, image_url: frame216 },
//     { id: 17, image_url: frame135 },
//     { id: 18, image_url: frame1561 },
//     { id: 19, image_url: ET, link: "https://economictimes.indiatimes.com/tech/startups/animeta-agritech-improves-the-dairy-farm[…]-digital-solutions-/articleshow/104068969.cms?backto=1" },
//     { id: 20, image_url: cohort },
//     { id: 21, image_url: biracs },
//     { id: 22, image_url: AgriBusiness },

//   ];

//   return (
//     <Carousel controls={false} interval={null} style={{background: 'none', padding:'0px', opacity: 'initial'}}>
//       <Carousel.Item>
//         <div className='container-fluid' style={{ backgroundColor: '#FFF3F5', padding: '2rem 0' }}>
//           <div className='container'>
//             <div className="New_achievement_div_cls">
//               {t("Achievements")}
//             </div>
//             <div className='treatment-page-container'>
//               <Slider {...settings}>
//                 {products.map((product) => (
//                   <div key={product.id}>
//                     <a href={product.link} target="_blank" rel="noopener noreferrer">
//                       <img className='Team-Grid-img' src={product.image_url} alt={`Product ${product.id}`} />
//                     </a>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         </div>
//       </Carousel.Item>
//     </Carousel>
//   );
// };

// export default TreatmentDocPage;

import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { serverDataLanguage } from "../../ServerDataLanguage/index";
import homeActions from "../../../Redux/Home/action";
import styled from "styled-components";

const TreatmentDocPage = (props) => {
  const { achievement } = props;
  const { t } = useTranslation();
  const { teamContent } = useSelector((state) => state.AuthReducer);
  const serverData = serverDataLanguage(teamContent);
  const dispatch = useDispatch();

  let [achievements, setAchievements] = useState([]);
  let [hover, sethover] = useState(null);

  useEffect(() => {
    dispatch({
      type: homeActions.GET_TEAM_CONTENTS,
      payload: achievement,
    });
  }, [dispatch]);

  useEffect(() => {
    setAchievements(() =>
      serverData?.filter((val) => {
        if (val?.type === achievement) {
          return val;
        }
      })
    );
  }, [teamContent]);

  const handleHover = (e) => {
    sethover(e.target.id);
  };

  const Clamptext = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    lineheight: 14.2em;
    fontsize: 14px;
    fontweight: 500;
    color: #232c63;
    padding: 5px 15px 0px 15px;

    //   &:hover {
    //     /* Change color when hovered */
    // }
  `;

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Carousel controls={false} interval={null}>
      <Carousel.Item>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#FFF3F5", padding: "2rem 0" }}
        >
          <div className="container">
            <div className="New_achievement_div_cls">
              {t("Our Achievements")}
            </div>
            <div className="treatment-page-container">
              <Slider {...settings}>

                {/* {achievements?.map((product) => (
                  <div className="hello" style={{position: 'relative'}} key={product.id}><img src={product.img_url} alt="" className="Team-Grid-img" />
                  
                  <div style={{position: 'absolute', top: '88%', background: 'red', width: '100%'}}>
                    hello
                    <div>{product.title}</div>
                  </div>
                  </div>
                ))} */}


                {achievements?.map((product) => (
                  <div key={product.id} className="achievement-img-aligner" style={{position: 'relative'}}>
                    <img
                      className="Team-Grid-img"
                      // className="achievements-img"
                      src={product.img_url}
                      alt=""
                      onClick={() => product.link}
                    />
                    <div
                    style={{position: 'absolute'}}
                      className={
                        hover == product.id
                          ? "achievement-hover"
                          : "achievement-shortdiscription"
                      }
                      id={product.id}
                      onMouseOver={handleHover}
                      onMouseOut={() => {
                        sethover(null);
                      }}
                      onClickCapture={() =>
                        window.open(product.link, "_blank", "noreferrer")
                      }
                    >
                      <div
                        className={
                          hover == product.id
                            ? "achievement-Short-Title-Hover"
                            : "achievement-Short-Title"
                        }
                        // className="treatment-page-container"
                        id={product.id}
                        onMouseOver={handleHover}
                        onMouseOut={() => {
                          sethover(null);
                        }}
                      >
                        {product.title}
                      </div>

                      {product?.contents.map((item) =>
                        hover == product.id ? (
                          <>
                            <Clamptext
                              id={product.id}
                              onMouseEnter={handleHover}
                              // onMouseOver={handleHover(product.id)}
                              onMouseOut={() => {
                                sethover(null);
                              }}
                            >
                              {item?.descirption}
                            </Clamptext>
                          </>
                        ) : null
                      )}
                    </div>

                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default TreatmentDocPage;
