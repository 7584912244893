import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../Redux/common/actions";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { AlphabetsValidation } from "../../utils/validation";

export default function EditAddressModal(props) {

    const { t } = useTranslation();

    const { showModal, closeModal, address, handleUpdateAddress } = props;

    const dispatch = useDispatch();

    const { stateList } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    useEffect(() => {
        reset({...address});
        //eslint-disable-next-line
    }, [address]);

    useEffect(() => {
        dispatch({ type: commonActions.GET_STATES });
        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        handleUpdateAddress(data);
    };

    return (
        <div>
            <Modal
                dialogClassName="login_modal"
                show={showModal}
                onHide={closeModal}
                centered
                size="lg"
            >
                <div className="modal_close_icons"
                    onClick={closeModal}
                >
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </div>
                <Container className="my-4">
                    <Row>
                        <Col md={12} className="login-modal-row">
                            <div className="login_title">
                                <h4 className="login-modal-h5">{t("Update Your Address")}</h4>
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Address type")}<span className="fw-bold text-danger">*</span></label>
                                <select
                                    className="form-control"
                                    {...register('address_type', {
                                        required: (t("*required"))
                                    })}
                                >
                                    <option value="Home">Home</option>
                                    <option value="Work">Work</option>
                                    <option value="Other">Other</option>
                                </select>
                                {errors?.address_type &&
                                    <small className="text-danger">{errors?.address_type?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Door No/ Flat No")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    {...register('address_line1', {
                                        required: (t("*required"))
                                    })}
                                />
                                {errors?.address_line1 &&
                                    <small className="text-danger">{errors?.address_line1?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Street Name")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                
                                    className="form-control"
                                    {...register('address_line2', {
                                        required: (t("*required"))
                                    })}
                                />
                                {errors?.address_line2 &&
                                    <small className="text-danger">{errors?.address_line2?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Town")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    {...register('address_line3', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                />
                                {errors?.address_line3 &&
                                    <small className="text-danger">{errors?.address_line3?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("District")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    
                                    className="form-control"
                                    {...register('city', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                />
                                {errors?.city &&
                                    <small className="text-danger">{errors?.city?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Pincode")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    {...register('pincode', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^\d{6}$/,
                                            message: "Please enter the valid pincode"
                                        }
                                    })}
                                />
                                {errors?.pincode &&
                                    <small className="text-danger">{errors?.pincode?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("State")}<span className="fw-bold text-danger">*</span></label>
                                <select
                                    className="form-control form-select"
                                    {...register('state', {
                                        required: (t("*required"))
                                    })}
                                >
                                    {stateList?.map((val, i) =>
                                        <option key={i}>{val?.state_name}</option>
                                    )}
                                </select>
                                {errors?.state &&
                                    <small className="text-danger">{errors?.state?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Country")}<span className="fw-bold text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    {...register('country', {
                                        required: (t("*required")),
                                        pattern: {
                                            value: /^[a-zA-Z ]*$/,
                                            message: "Only alphabet characters are allowed",
                                        },
                                    })}
                                    onInput={AlphabetsValidation}
                                    defaultValue='India'
                                />
                                {errors?.country &&
                                    <small className="text-danger">{errors?.country?.message}</small>
                                }
                            </div>
                        </Col>
                        <Col md={6} className="pb-3">
                            <div className="form-group">
                                <label className="form-label">{t("Landmark")}</label>
                                <input
                                
                                    className="form-control"
                                    {...register('other_address_details')}
                                />
                            </div>
                        </Col>
                        <Col md={12} className="pb-3 d-flex justify-content-center">
                            <Button size="md" onClick={handleSubmit(onSubmit)}>
                                {t("Update Address")}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </div>
    )
};